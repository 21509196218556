body.register-confirm {
    #form-box-wrapper {
        color: $text-color;

        .message {
            font-size: 14px;
            font-weight: 100;
            color: $text-color;
        }

        .btn-form-submit {
            margin: 0 0;
        }
        .sign {
            color: $text-color;
            font-size: 20px;
            font-weight: 100;

            &.brand {
                color: $af-rosso;
                font-family: 'Eugenio Serif';
            }
        }
        hr {
            border-bottom: 1px solid $af-rosso;
            width: 50%;
        }
    }

    .title {
        font-size: 16px;
        color: $af-rosso;
        font-weight: 100;
    }

    #back-button:hover {
        color: black !important;
    }

    @media (min-width: 768px) {
        #form-box-wrapper {
            .message {
                font-size: 20px;
            }

            .title {
                font-size: 24px;
            }
        }
    }

    @media (min-width: 1400px) {
        #form-box-wrapper {
            .message {
                font-size: 25px;
            }

            .title {
                font-size: 30px;
            }
            .sign {
                font-size: 25px;
            }
            hr {
                width: 60%;
            }
        }
    }
}
