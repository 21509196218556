.evt-subheader {
    padding-top: 24px;
    padding-bottom: 48px;
    text-wrap: balance;
    background-color: $rosso;
    color: white;
    text-align: center;

    .logo-box {
        margin: 0 auto;
    }

    .container-fluid {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 36px;
        p {
            font-size: 20px;
            font-weight: 300;
            letter-spacing: 0.04em;
            max-width: 768px;
            margin: 0 auto;
        }

        p.evt-details-date {
            font-size: 21px;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 0;
        }
        p.evt-details-location {
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 0;
        }
        hr {
            border: 1px solid white;
            align-self: stretch;
            margin: 12px 0;
        }

        .img-box {
            max-width: 300px;
        }
        .smaller {
            font-size: 80%;
        }
        .evt-subtitle {
            font-size: 42px;
            line-height: 48px;
            margin-top: 12px;
            font-weight: 700;
            hr {
                border: 0.5px solid white;
                margin: 1rem 0;
            }
        }
    }
}

@media (min-width: 500px) {
    .evt-subheader {
        .evt-title {
            font-size: 40px;
            line-height: 40px;
        }
    }
}

@media (min-width: 768px) {
    .evt-subheader {
        .logo-box {
            max-width: 600px;
            position: relative;
            z-index: 3;
        }
        .container-fluid {
            display: flex;
            flex-direction: column;
            align-items: left;

            p.evt-details-date {
                font-size: 30px;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 0;
            }
            p.evt-details-location {
                font-size: 24px;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 0;
            }
            hr {
                border: 1px solid white;
                align-self: stretch;
                margin: 12px 0;
            }

            .img-box {
                max-width: 512px;
            }
            .evt-subtitle {
                font-size: 48px;
                line-height: 48px;
                text-align: center;
                margin-top: 12px;
                font-weight: 700;
                hr {
                    border: 0.5px solid white;
                    margin: 1rem 0;
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    .evt-subheader {
        .container-fluid {
            display: flex;
            flex-direction: row;
            justify-content: center;
            max-width: 900px;
            column-gap: 24px;

            .evt-box {
                flex-basis: 50%;
            }

            hr {
                border: 1px solid white;
                align-self: stretch;
                margin: 12px 0;
            }
            .logo-box {
                max-width: 768px;
            }
            .img-box {
                flex-basis: 50%;
            }
            .smaller {
                font-size: 80%;
            }
            .evt-subtitle {
                font-size: 48px;
                line-height: 1;
                text-align: center;
                margin-top: 12px;
                font-weight: 600;
                hr {
                    border: 0.5px solid white;
                    margin: 1rem 0;
                }
            }
        }
    }
}
@media (min-width: 1280px) {
    .evt-subheader {
        .container-fluid {
            max-width: 1280px;
            column-gap: 48px;
        }
    }
}
