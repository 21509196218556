#partners-group {
    .knowledge-partners {
        color: #6e6e6e;
        font-weight: 400;
        font-size: 24px;
        text-align: center;
        margin-bottom: 24px;
    }
    padding: 1rem 0;
    .partners {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 24px;
    }
    .partners > .partner {
        height: 200px;
        width: 350px;
        background-color: #eee;
    }
}
