.btn-form-submit {
    color: $text-reverse;
    background-color: $af-rosso;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 16px;
    margin: 0 auto;
    padding: 8px 36px;
    text-align: center;
    font-weight: 400;
    // border: 1px solid $text-highlight;

    a:hover {
        color: $af-bianco;
    }

    &:hover,
    &:active {
        font-weight: bold;
        color: $af-bianco;
        // background-color: $text-highlight;
        // border: 1px solid $text-highlight;
    }
    &:focus,
    &:active {
        transform: scale(0.95);
        // color: $text-reverse;
        // background-color: $text-highlight;
        // border: 1px solid $text-highlight;
    }

    @media (min-width: 768px) {
        font-size: 19px;
    }
}

// buttons for languages
ul.header__languages.d-flex {
    justify-content: end;
    margin-bottom: 15px;
    @media screen and (min-width: 980px) {
        justify-content: center;
        margin-bottom: 0;
    }

    li {
        margin: 0 0 0 10px;
        @media screen and (min-width: 980px) {
            margin: 0 10px;
        }

        div {
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 30px;
            height: 25px;
            background-size: 30px;
            background-repeat: no-repeat;
            background-position: center center;
            opacity: 0.5;
            &.lang-active {
                opacity: 1 !important;
            }
            &:hover {
                opacity: 1 !important;
            }
        }
    }
}

.btn-partner {
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: $text-reverse;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    margin-top: 1rem;

    a:hover {
        color: $af-bianco;
    }

    &:hover,
    &:active {
        font-weight: bold;
        color: $af-bianco;
        // background-color: $text-highlight;
        // border: 1px solid $text-highlight;
    }
    &:focus,
    &:active {
        transform: scale(0.95);
        // color: $text-reverse;
        // background-color: $text-highlight;
        // border: 1px solid $text-highlight;
    }

    @media (min-width: 1024px) {
        font-size: 18px;
        margin-top: 2rem;
    }
}

#btn-morning {
    margin: 48px auto 96px auto;
    display: block;
    width: 200px;
    border: 1px solid white;
}
