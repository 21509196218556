.page-bck {
    background: white;
}
@media (orientation: landscape) {
}
h1,
h2,
h3 {
    font-weight: bold;
}
#view {
    min-height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
}

#view.view-vertical {
    display: flex;
    flex-direction: column;
}
#view.view-horizontal {
    height: 100%;
}

#main {
    //padding: 1rem 0.5rem;
    flex-grow: 1;

    &.main-content-h {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
    &.main-content-v {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    &.main-content-vl {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    .content {
        &.morning {
            background-color: $af-bianco;
            margin-bottom: 3rem;
        }
        &.afternoon {
            background-color: $af-bianco;
            margin-bottom: 3rem;
        }
    }
}

.left-content {
    max-width: 700px;
}
#header-box {
    display: flex;
    align-items: center;

    .logo-main {
        margin-right: 2rem;
    }

    .logo {
    }
}
#content-box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    max-width: 90%;
    margin: 0 auto;
    padding: 2rem 0;
}

#countdown-box {
    background-color: #000;
    border: 5px solid #fff;
    border-radius: 10px;
    // box-shadow: 0px 10px 20px #000;
    max-width: 950px;
    margin: 0 auto;

    h1 {
        font-size: 20px;
        font-weight: bold;
        margin-top: 35px;
    }

    .content {
        text-align: center;
        padding: 25px 0;

        .cmsg {
            color: $text-color;
            font-weight: bold;
        }
    }
}

#footer-box {
    .footer-logo {
        width: 120px;
    }
}

#logo-message-box {
    .logo-box {
        width: 120px;
        margin: 0 auto;
    }

    .message-box {
        margin: 1rem 0;

        .message,
        .msg-1,
        .msg-2,
        .msg-3 {
            line-height: 1;
        }

        .message {
            font-size: 16px;
        }
        .msg-1 {
            font-size: 30px;
        }
        .msg-2 {
            font-size: 26px;
        }
        .msg-3 {
            font-size: 22px;
        }
    }
}

.omg {
    color: $text-color;
}

#form-box {
    margin-top: 2rem;
    //max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    .content {
        padding: 36px 1rem;
        background-color: $af-bianco;
        position: relative;

        &.large-padding {
            padding-top: 38px;
        }
    }
    // max-width: 500px;
    // margin: 0 auto;

    // box-shadow: 0px 10px 20px #000;
}

.page-title {
    position: relative;
    margin-bottom: 35px;
    font-weight: 600;
    font-size: 30px;
    display: inline-block;

    &:after {
        content: '';
        width: 120px;
        height: 5px;
        background-color: $text-highlight;
        position: absolute;
        bottom: -20px;
        left: 0;
    }
}

#message-box {
    //padding: 1.5rem;
    // background-color: #fff;
    // box-shadow: 0px 10px 20px #000;
    .content {
        padding: 28px 1rem;
        background-color: $af-bianco;
        position: relative;

        &.large-padding {
            padding-top: 38px;
        }
    }

    .main-title {
        //border-bottom: 3px solid $text-highlight;
        font-size: 48px;
        padding: 15px 0;
        margin: 0;
    }
    .divider {
        width: 100px;
        border-bottom: 2px solid;
        margin: 0 auto;
        margin-bottom: 15px;
    }

    .msg {
        font-size: 24px;
    }

    .main-title,
    .message,
    .msg-1,
    .msg-2,
    .msg-3 {
        line-height: 1;
    }

    .message {
        font-size: 16px;
    }
    .msg-1 {
        font-size: 30px;
    }
    .msg-2 {
        font-size: 26px;
    }
    .msg-3 {
        font-size: 22px;
    }

    .inner {
        margin-top: 0.5rem;

        &.success,
        &.error {
            border: 2px dashed;
            padding: 1rem 1.5rem;
        }
        &.success {
            border-color: $text-color;
        }
        &.error {
            border-color: $text-error;
        }
    }

    .omg {
        font-size: 16px;
    }

    .btn-form-submit {
        border: 1px solid transparent;
    }

    margin-top: 1rem;
    //max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.page-embed {
    color: #000;

    #form-box {
        box-shadow: none;
    }

    #message-box {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        box-shadow: none;

        .logo-embed {
            width: 100px;
            margin: 1rem auto;
        }
    }
}

.pharma-name,
.pharma-data {
    line-height: 1;
}

#action-box {
    .modal-header {
        background-color: $text-black;
        font-weight: bold;
        border: 2px solid $text-color;
        text-align: center;

        .close {
            color: $text-color;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}
@media (min-width: 360px) {
}

@media (min-width: 500px) {
    #logo-message-box {
        .logo-box {
            width: 220px;
        }

        .message-box {
        }
    }
}

@media (min-width: 768px) {
    #countdown-box {
        .cmsg {
            font-size: 32px;
        }
    }

    #message-box {
        // padding: 2.5rem 4rem;

        .main-title {
            font-size: 88px;
        }

        .msg {
            font-size: 28px;
        }

        .msg-1 {
            font-size: 58px;
        }
        .msg-2 {
            font-size: 50px;
        }
        .msg-3 {
            font-size: 40px;
        }

        .omg {
            font-size: 22px;
        }
        .message {
            font-size: 22px;
            line-height: 1.3;

            span {
                display: block;
            }
        }
    }

    #logo-message-box {
        .logo-box {
            width: 220px;
            margin: 0;
        }

        .message-box {
            .msg-1 {
                font-size: 58px;
            }
            .msg-2 {
                font-size: 50px;
            }
            .msg-3 {
                font-size: 40px;
            }

            .omg {
                font-size: 22px;
            }
            .message {
                font-size: 22px;
                line-height: 1.3;

                span {
                    display: block;
                }
            }
        }
    }
}
@media (min-width: 992px) {
    #main {
        // padding-left: 2vw;
        // padding-right: 2vw;

        &.main-content-h {
            flex-direction: row;
            align-items: center;
        }

        #logo-message-box {
            // flex-basis:66.66%;

            .logo-box {
                width: auto;
                max-width: 520px;
                margin: 0 auto;
            }

            .message-box {
                text-align: center;

                .msg-1 {
                    font-size: 90px;
                }
                .msg-2 {
                    font-size: 80px;
                }
                .msg-3 {
                    font-size: 50px;
                }

                .omg {
                    font-size: 30px;
                }
                .message {
                    font-size: 30px;

                    span {
                        display: block;
                    }
                }
            }
        }
    }

    #message-box {
        // padding: 2.5rem 4rem;

        .main-title {
            font-size: 136px;
        }

        .msg-1 {
            font-size: 90px;
        }
        .msg-2 {
            font-size: 80px;
        }
        .msg-3 {
            font-size: 50px;
        }

        .omg {
            font-size: 30px;
        }
        .message {
            font-size: 30px;

            span {
                display: block;
            }
        }
    }

    .page-title {
        font-size: 4rem;
    }

    #content-box {
    }

    .big-copy {
    }

    #countdown-box {
        .cmsg {
            font-size: 42px;
        }
    }
}

@media (min-width: 1200px) {
    #form-box {
        //padding: 3.5rem 7rem;
    }

    #message-box {
        //padding: 3.5rem 5rem;

        .main-title {
        }

        .msg {
            font-size: 30px;
        }
    }
}

@media (min-width: 1400px) {
}
@media (min-width: 1400px) {
}

@media (min-width: 1600px) {
}
