$submit-color: $text-color;
$submit-bg: $text-reverse;

$sort-box-bg: #ddd;

$icon-check-height: 18px;
$icon-check-width: 10px;
$icon-check-top: 5px;
$icon-check-left: 10px;
$icon-check-border-color: $text-reverse;
$icon-check-border-width: 3px;

$vote-icon-color: darkgrey;
$vote-color: #4dd95b;

$bubble-color: $text-reverse;
$bubble-bg: #1d1d1b;

$bubble-color-odd: $text-reverse;
$bubble-bg-odd: #8a8e91;

$bubble-reply-color: $text-color;
$bubble-reply-bg: $text-reverse;

$bubble-reply-color-odd: $text-color;
$bubble-reply-bg-odd: $text-reverse;

$milc-user-icon-color: #000;
$milc-user-icon-bg: #fff;
$milc-user-icon-border-color: transparent;

#milc-chat-pan.milc-v3 {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    //background-color: #fff;
    //padding: 35px 15px 0 15px;

    &.closed {
        .milc-send-box {
            display: none;
        }

        .milc-closed-box {
            display: flex;
        }
    }
    .milc-closed-box {
        display: none;
        justify-content: center;
        align-content: center;
        padding: 10px 5px;
        font-weight: 600;
    }

    .milc-body {
        margin-top: 1rem;
        border-bottom: 0;
        padding: 0;
        flex: 1;
        max-height: 500px;
        display: flex;
        //display: none;
        flex-direction: column;
        overflow-y: auto;
        position: relative;
        scroll-snap-type: y proximity;
        scroll-padding-top: 35px;
    }

    .milc-user-box {
        display: flex;
        align-items: baseline;
        color: $text-color;
        padding: 10px 0;

        .fa {
            font-size: 24px;
        }
    }

    .milc-username {
        margin-left: auto;
        font-weight: bold;
    }

    #milc-send-text {
        resize: none;
        outline: none;
        box-shadow: none;
        padding-right: 35px;
        padding-left: 5px;
        //border: 0 none;
    }

    .milc-send-box {
        position: relative;
        .wrapper,
        .milc-send-text-outer {
            position: relative;
        }

        .emoji-trigger {
            position: absolute;
            top: 2px;
            right: 5px;
            text-decoration: none;
        }
        .emoji-picker {
            position: absolute;
            right: 2px;
            top: 20px;
            z-index: 999;
            display: none;
            width: 175px;
            padding: 5px 2px 5px 5px;
            margin-top: 5px;
            overflow: hidden;
            background: #fff;
            border: 1px #dfdfdf solid;
            border-radius: 3px;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        }
        .emoji-picker-ul {
            padding: 0;
            margin: 0;
            list-style: none;
        }
        .emoji-picker-li {
            display: inline-block;
            margin: 5px;
        }
        .emoji-picker-a {
            text-decoration: none;
            margin: 5px;
            position: initial;
            font-size: 24px;
        }
    }

    #milc-chat-counter {
        position: absolute;
        right: 5px;
        bottom: 15px;
        width: 27px;
        height: 27px;

        .progressbar__label {
            font-size: 10px;
        }

        svg {
            height: 100%;
            display: block;
        }
    }

    .submit-wrapper {
        text-align: center;
    }
    #milc-send-submit {
        width: 100%;
        margin: 0 auto;
        font-weight: bold;
        font-size: 20px;
        // padding-top: 3px;
        // padding-bottom: 2px;
        // background: rgb(255,255,255);
        // background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(210,203,189,1) 50%, rgba(255,255,255,1) 100%);
        // border-radius: 5px;
        color: $submit-color;

        background-color: $submit-bg;
        // height: 42px;
        // margin: 0 auto;
        padding: 5px 35px;
        //margin-bottom: 15px;

        &:focus,
        &:active {
            transform: scale(0.95);
            // color: $text-reverse;
            // background-color: $text-highlight;
            // border: 1px solid $text-highlight;
        }
    }

    .milc-sort-box {
        padding: 5px 10px;
        background-color: $sort-box-bg;

        &.empty {
            display: none;
        }
    }

    #msg-confirm {
        order: 4;
        display: flex;
        color: $msg-confirm-color;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $msg-confirm-bg;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }

    #milc-messages.milc-box {
        background-color: #fff;
        color: $text-reverse;
        scroll-snap-type: y proximity;
        list-style-type: none;
        padding: 0 5px;

        li {
            padding: 0 10px 0 0;
            transition: all 0.4s ease-out;
            opacity: 0;
            height: 0;
            margin-bottom: 15px;
            scroll-snap-align: start;

            &.show {
                opacity: 1;
                height: auto;
            }
        }
        .milc-msg-author {
            //font-weight: 300;
            //font-size: 90%;
            font-weight: bold;
            margin-top: 10px;
            line-height: 1.1;
        }

        .milc-msg-text {
            overflow-wrap: anywhere;
            //letter-spacing: -1px;
            padding: 10px 0;
            line-height: 1.1;
        }

        .milc-msg-footer {
            font-style: italic;
            font-size: 70%;
            text-align: right;
        }

        .milc-msg-reply {
            font-size: 90%;
            margin: 0 0 5px 5px;
            border-left: 2px dashed #c7c7c5;
            padding: 10px 0 10px 15px;
        }

        .milc-msg {
            //display: flex;

            .milc-msg-left {
                min-width: 60px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                margin-top: 15px;
                display: none;
            }

            .milc-msg-right {
                //flex-grow: 1;
            }
        }

        .milc-msg-vote-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid;
            border-radius: 100%;
            padding: 7px 10px 10px 10px;
            cursor: pointer;
            color: $vote-icon-color;
            margin-bottom: auto;

            .fa-play {
                transform: rotate(-90deg);
            }

            &:hover {
                border-color: $text-highlight;
                color: $text-highlight;
            }

            &.voted {
                cursor: auto;
                border-color: transparent;
                color: $vote-color;
            }
        }
    }

    #milc-messages.milc-box-bubble {
        //background-color: #1d1d1b;
        //color: $text-color;
        list-style-type: none;
        padding: 0 5px;

        li {
            padding: 0 10px 0 0;
            transition: all 0.4s ease-out;
            opacity: 0;
            height: 0;
            margin-bottom: 15px;
            scroll-snap-align: start;

            .inner {
                display: flex;
                align-items: center;
            }

            &.show {
                opacity: 1;
                height: auto;
            }
        }

        .milc-user-icon {
            font-size: 24px;
            border: 1px solid $milc-user-icon-border-color;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 40px;
            margin-right: 30px;
            color: $milc-user-icon-color;
            background-color: $milc-user-icon-bg;
        }
        .milc-msg-author {
            font-weight: bold;
            line-height: 1.1;
        }
        .milc-msg-text {
            overflow-wrap: anywhere;
            //letter-spacing: -1px;
            padding: 5px 0 5px 0;
            line-height: 1.1;
        }
        .milc-msg-reply {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 1rem;

            label {
                display: block;
                font-weight: bold;
                margin-bottom: 5px;
            }

            .milc-user-icon {
                margin-left: 30px;
                margin-right: 0px;
                //color: $text-reverse;
                //background-color: $text-color;
            }
        }
        .milc-msg-bubble,
        .milc-msg-bubble-reply {
            flex-grow: 1;
            padding: 5px 8px;
            position: relative;
            border-radius: 0.4em;
            line-height: 1.1;
        }
        .milc-msg-bubble {
            color: $bubble-color;
            background-color: $bubble-bg;
        }
        .milc-msg-bubble-reply {
            color: $bubble-reply-color;
            background-color: $bubble-reply-bg;
            border: 1px dashed $text-color;
        }

        .milc-msg-bubble:after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 0;
            height: 0;
            border: 13px solid transparent;
            border-right-color: $bubble-bg;
            border-left: 0;
            margin-top: -13px;
            margin-left: -13px;
        }

        .milc-msg-bubble-reply:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            width: 0;
            height: 0;
            border: 13px solid transparent;
            border-left-color: $bubble-reply-bg;
            border-right: 0;
            margin-top: -13px;
            margin-right: -13px;
        }

        li:nth-child(even) {
            .milc-msg-bubble {
                color: $bubble-color-odd;
                background-color: $bubble-bg-odd;
            }
            .milc-msg-bubble-reply {
                color: $bubble-reply-color-odd;
                background-color: $bubble-reply-bg-odd;
            }
            .milc-msg-bubble:after {
                border-right-color: $bubble-bg-odd;
            }
            .milc-msg-bubble-reply:after {
                border-left-color: $bubble-reply-bg-odd;
            }
        }

        .milc-msg-bubble-row {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            .milc-msg-data {
                margin-left: auto;
                font-style: italic;
                font-size: 14px;
            }
        }
        .milc-msg-bote-box {
            display: none;
        }
    }

    #milc-messages-sorter {
        display: flex;
        display: none;
        justify-content: space-between;
        position: sticky;
        top: 0;
        z-index: 1;
        font-size: 14px;
        font-weight: bold;
    }

    .sort-select {
        position: relative;
    }

    .sort-select select {
        display: none;
    }

    .select-selected {
        width: 120px;
    }

    /* Style the arrow inside the select element: */
    .select-selected:after {
        position: absolute;
        content: '';
        top: 10px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: $text-reverse transparent transparent transparent;
    }

    /* Point the arrow upwards when the select box is open (active): */
    .select-selected.select-arrow-active:after {
        border-color: transparent transparent $text-reverse transparent;
        top: 3px;
    }

    /* style the items (options), including the selected item: */
    .select-items div {
        padding: 8px 15px 3px 30px;
        border: 1px solid transparent;
        cursor: pointer;
        position: relative;
    }

    .select-selected {
        padding: 0;
        border: 1px solid transparent;
        cursor: pointer;
    }

    /* Style items (options): */
    .select-items {
        position: absolute;
        background-color: $text-color;
        color: $text-reverse;
        top: 30px;
        left: -30px;
        right: 0;
        z-index: 99;
    }

    /* Hide the items when the select box is closed: */
    .select-hide {
        display: none;
    }

    .select-items div:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .same-as-selected {
        font-weight: bold;

        &:before {
            content: '';
            position: absolute;
            top: $icon-check-top;
            left: $icon-check-left;
            display: inline-block;
            transform: rotate(45deg);
            height: $icon-check-height;
            width: $icon-check-width;
            border-bottom: $icon-check-border-width solid $icon-check-border-color;
            border-right: $icon-check-border-width solid $icon-check-border-color;
        }
    }

    #poll-container {
        height: 100%;
        background-color: $dark-background;
        padding: 2rem 25px;

        max-width: 500px;
        margin: 25px auto;
        height: 500px;

        @media (min-width: 992px) {
            margin: 0;
        }

        .poll {
            background-color: #1d1d1b;

            padding: 2rem;

            .poll-body {
                color: #eee;
                font-size: 18px;
            }

            .poll-title {
                font-size: 18px;
            }

            .poll-reply {
                border: 1px solid darkgrey;
                width: 100%;
                padding: 5px;
            }

            .poll-reply::placeholder {
                color: #bfbfbf;
                font-weight: 300;
            }

            .poll-reply::-webkit-input-placeholder {
                color: #bfbfbf;
                font-weight: 300;
            }

            .poll-reply::-ms-input-placeholder {
                color: #bfbfbf;
                font-weight: 300;
            }
        }

        .rating.color-default .star-container .star i {
            color: $star-color;
        }

        .prev-btn,
        .next-btn {
            width: 3rem;
            height: 3rem;
            background: transparent;
            border: transparent;
            text-decoration: none;
        }

        .prev-btn:hover,
        .next-btn:hover {
            filter: grayscale(100%) brightness(200%);
        }

        .prev-btn:focus,
        .next-btn:focus {
            outline: 0;
        }

        .line-dx {
            background-image: url('/assets/img/line-h1.svg');
            background-repeat: no-repeat;
            background-size: cover;
            height: 5px;
            width: 100%;
            margin: 0rem 0 2rem 0;
        }
    }
}

#milc-chat-pan.milc-v3.milc-header-bottom {
    .milc-header {
        order: 2;
    }
    .milc-body {
        margin-top: 0;
        max-height: none;
    }
    .milc-send-box {
        padding-top: 0.5rem;
    }

    .milc-send-box .emoji-picker {
        top: -5px;
        //transform: translateY(-100%);
        transform: translateY(155%);
        width: auto;
    }
}

.no-messages {
    .milc-body {
        display: none !important;
    }
}
