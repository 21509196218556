.pass-graybar {
    height: 3px;
    background-color: #ccc;
    width: 100%;
    position: relative;
}

.pass-colorbar {
    height: 3px;
    background-image: url('/assets/img/passwordstrength.jpg');
    position: absolute;
    top: 0;
    left: 0;
}

.pass-percent,
.pass-text {
    font-size: 14px;
}

.pass-percent {
    margin-right: 5px;
}
