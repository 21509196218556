body.landing {
    header.main-header-special .main-nav {
        max-width: 650px;
        margin: 0 auto;
    }

    #main {
        background-color: rgba($color: #000, $alpha: 0.1);
        .lang-mobile {
            display: flex;
            justify-content: end;
        }
        .lang-desktop {
            display: none;
        }
        .container {
            max-width: 900px;
        }
        #program-title {
            h2 {
                font-weight: 300;
                font-size: 48px;
                font-family: 'Eugenio Sans';
                color: $text-color;
                text-align: center;
            }
            hr {
                border: 1px solid $af-rosso;
            }
        }

        #program-evt-box {
            flex-basis: 60%;
            height: 100vh;

            .content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                align-items: center;
                padding: 1rem 1rem 1rem 1rem;

                .evt-description {
                    text-align: center;
                    font-weight: 600;
                    font-size: 21px;
                }

                .evt-program {
                    .title {
                        font-weight: 100;
                        font-size: 36px;
                        margin-top: 30px;
                        text-align: center;
                        color: white;
                    }

                    .program {
                        display: flex;
                        flex-direction: column;
                        .evt-conference {
                            flex-basis: 33%;
                            display: flex;
                            flex-direction: column;
                        }

                        .evt-workshop {
                            flex-basis: 33%;
                            display: flex;
                            flex-direction: column;
                        }

                        .evt-community {
                            flex-basis: 33%;
                            display: flex;
                            flex-direction: column;
                        }

                        .program-title {
                            font-weight: bold;
                            font-size: 25px;
                            text-align: center;
                            hr {
                                border: 1px solid white;
                            }
                        }

                        .program-text {
                            text-transform: none;
                            text-align: center;
                            flex-grow: 1;

                            a {
                                color: black;
                                text-decoration: underline;
                            }
                            a:hover {
                                color: $af-bianco;
                            }
                        }
                    }
                }
            }
        }
    }

    #program-box {
        #morning {
            margin-bottom: 1.5rem;
            .evt-hour {
                font-size: 24px;
            }
            font-size: 20px;
            p {
                text-align: center;
            }
            hr {
                border: 1px solid $af-rosso;
            }

            .morning-container {
                display: flex;
                flex-direction: column;
                gap: 24px;
                text-wrap: balance;
                .evt-layout {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    text-align: center;
                    justify-content: space-between;
                }
                .evt-time {
                    font-weight: 600;
                    font-size: 24px;
                    color: $text-color;
                }
                .location {
                    font-weight: bold;
                    font-size: 22px;
                }
                .title {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 1.35;
                    color: $af-rosso;
                    margin-bottom: 12px;
                }
                .speaker,
                .moderator {
                    color: $text-color;
                    font-weight: 700;
                    line-height: 1.5em;
                    i,
                    .con {
                        color: $text-color;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    @media (min-width: 768px) {
        #program-box {
            #morning {
                font-size: 19px;

                .evt-hour {
                    text-align: left;
                }
                p {
                    text-align: center;
                }
            }
        }

        #main {
            .title-moment {
                padding: 0 5rem;
                font-size: 18px;
            }

            #program-evt-box {
                .content {
                    padding: 1rem 2rem 1rem 2rem;

                    .evt-description {
                        font-size: 25px;
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px) {
    body.landing {
        #program-box {
            #morning {
                margin-bottom: 1.5rem;
                .evt-hour {
                    font-size: 24px;
                }
                font-size: 20px;
                p {
                    text-align: center;
                }

                .morning-container {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    text-wrap: balance;
                    .evt-layout {
                        display: flex;
                        flex-direction: row;
                        gap: 6px;
                        text-align: left;
                        justify-content: space-between;
                    }
                    .evt-time {
                        font-weight: 600;
                        font-size: 24px;
                        color: $text-color;
                        width: 25%;
                    }
                    .evt-text {
                        flex-basis: 75%;
                    }
                    .location {
                        font-weight: bold;
                        font-size: 22px;
                    }
                    .title {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 1.35;
                        color: $af-rosso;
                        margin-bottom: 12px;
                    }
                    .speaker,
                    .moderator {
                        color: $text-color;
                        font-weight: 700;
                        line-height: 1.5em;
                        i,
                        .con {
                            color: $text-color;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }

    #main {
        .title-moment {
            padding: 0 12rem;
            font-size: 20px;
        }

        .lang-mobile {
            display: none;
        }
        .lang-desktop {
            display: flex;
            justify-content: flex-end;
        }

        #logo-evt-box {
            background: url(../img/linee-loop.png);
            background-position: right;
            background-repeat: repeat-y;
            background-size: 60px auto;

            .content {
                padding: 3rem 2rem 4rem 2rem;

                .logo-box {
                    max-width: 92%;
                }

                .text-box {
                    .text-title {
                        font-size: 27px;
                    }

                    .text-subtitle {
                        font-size: 27px;
                    }
                }
            }
        }

        #program-evt-box {
            .content {
                padding: 1rem;
                align-items: flex-end;
                .evt-description {
                    text-align: right;
                    font-size: 25px;
                }

                .evt-program {
                    .title {
                        text-align: unset;
                    }

                    .program {
                        flex-direction: row;
                        .program-title {
                            font-size: 20px;
                            text-align: unset;
                        }

                        .program-text {
                            font-size: 14px;
                            padding-right: 2rem;
                            text-align: unset;
                            button.landing {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1200px) {
    #program-box {
        #afternoon,
        #morning {
            .morning-container,
            .afternoon-container {
                font-size: 17px !important;
                .evt-layout {
                    display: flex;
                    .evt-time {
                        flex-basis: 25%;
                        //display: flex;
                        //align-items: center;
                    }
                }
            }
        }
    }

    #main {
        #logo-evt-box {
            .content {
                padding: 6rem;
                .text-box {
                    .text-title {
                        font-size: 25px;
                    }
                    .text-subtitle {
                        font-size: 25px;
                    }
                }
            }
        }

        #program-evt-box {
            .content {
                padding: 4rem;
                .evt-description {
                    font-size: 34px;
                }
                .evt-program {
                    .program {
                        .program-text {
                            button.landing {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1600px) {
    #program-box {
        #afternoon,
        #morning {
            .evt-hour {
                min-height: 100px;
            }
        }
    }

    #main {
        #logo-evt-box {
            .content {
                padding: 6rem;
                .text-box {
                    .text-title {
                        font-size: 33px;
                    }

                    .text-subtitle {
                        font-size: 33px;
                    }
                }
            }
        }
        #program-evt-box {
            .content {
                padding: 4rem;
                .evt-description {
                    font-size: 34px;
                }

                .evt-program {
                    .program {
                        .program-text {
                            button.landing {
                                font-size: 21px;
                            }
                        }
                    }
                }
            }
        }
    }
}
