.event-list {
    #main.main-content-v {
        align-items: flex-start;

        table thead tr th {
            border-left: 4px solid $af-rosso !important;
        }

        table tbody tr td {
            border-bottom: 1px transparent $af-rosso !important;
            border-top: 1px transparent $af-rosso !important;
            border-left: 4px solid $af-rosso !important;
            border-right: 4px solid $af-rosso !important;

            a:hover {
                text-decoration: underline;
            }
        }
        table tbody.workshop tr td {
            border-bottom: 2px trasparent $af-rosso !important;
            border-top: 4px solid $af-rosso !important;
            border-left: 4px solid $af-rosso !important;
            border-right: 4px solid $af-rosso !important;

            a {
                color: $af-rosso;
            }

            a:hover {
                color: $af-bianco;
                text-decoration: underline;
            }
        }
    }

    #title,
    #title-1,
    #title-2,
    #title-3 {
        color: $nero;
        font-size: 22px;
        font-weight: bold;

        a:hover {
            color: $af-rosso;
        }
    }

    #message-box {
        padding: 1rem 1rem;

        .program-header {
            display: flex;
            flex-direction: column;
            background: $af-rosso;
            color: white;
            padding: 1rem 2rem;
            font-size: 25px;
            .program-time {
                flex-basis: 25%;
                text-align: center;
                font-size: 24px;
            }
            .program-submit {
                flex-basis: 25%;
                text-align: center;
            }
            .program-title {
                flex-basis: 50%;
                text-align: center;
            }
        }

        .program-placeholder {
            background: white;
            padding: 2rem;

            .toggle-open {
                display: flex;
                justify-content: flex-end;
            }

            a:hover {
                color: $af-rosso;
            }
        }

        .toggle-close {
            background: white;
            padding: 2rem;
            display: flex;
            justify-content: flex-end;
            a:hover {
                color: $af-rosso;
            }
        }

        .program-table {
            table {
                margin-bottom: 0;
            }

            &.prog-panel {
                display: none;
            }
        }
    }

    .evt-room-text {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .evt-text {
        margin-bottom: 3rem;
        font-size: 18px;
    }

    a {
        color: black;
        text-decoration: underline;
        -webkit-appearance: caret;
    }

    a:hover {
        color: white;
    }

    .table-responsive {
        //@include responsive-table;
    }
    @media (min-width: 576px) {
        #title,
        #title-1,
        #title-2,
        #title-3 {
        }

        .evt-text {
            padding: 0 2rem;
        }
    }

    @media (min-width: 768px) {
        #message-box {
            .evt-day {
                padding: 2rem;
                .inline-title {
                    display: flex;
                    justify-content: space-between;
                    .program-title {
                        font-size: 35px;
                    }
                    .btn-form-submit {
                        font-size: 20px;
                    }
                }
                .evt-day-text {
                    display: flex;
                    justify-content: space-evenly;
                    .evt-day-text-hour {
                        border-bottom: none;
                        flex-basis: 20%;
                    }
                    .evt-day-text-description {
                        flex-basis: 80%;
                    }
                }
            }
        }
    }
    @media (min-width: 992px) {
        #main.main-content-v {
            table tbody tr td {
            }
            table tbody.workshop tr td {
                border-bottom: 4px solid $af-rosso !important;
                border-top: 4px solid $af-rosso !important;
                border-left: 4px solid $af-rosso !important;
                border-right: 4px solid $af-rosso !important;
            }
        }

        #title,
        #title-1,
        #title-2,
        #title-3 {
            font-size: 27px;
        }
        #message-box {
            padding: 1rem 2rem;

            .program-header {
                flex-direction: row;

                .program-time {
                    text-align: left;
                    font-size: 17px;
                }
                .program-submit {
                    text-align: right;
                }
                .program-title {
                    text-align: center;
                }
            }
        }
    }

    @media (min-width: 1220px) {
        #main.main-content-v {
            &.two-boxes {
                //overflow-y: hidden;
            }
        }
        #message-box {
            padding: 1rem 4rem;
            .program-header {
                flex-direction: row;

                .program-time {
                    font-size: 20px;
                }
                .program-submit {
                }
                .program-title {
                }
            }
        }
    }
    @media (min-width: 1600px) {
        #message-box {
            .evt-day {
                .inline-title {
                    .program-title {
                        font-size: 40px;
                    }
                    .btn-form-submit {
                        font-size: 25px;
                    }
                }
            }
            .program-header {
                flex-direction: row;

                .program-time {
                    font-size: 24px;
                }
                .program-submit {
                }
                .program-title {
                }
            }
        }
    }
}

.event-list.event-list-community {
    .event-28 {
        margin: 0 -1rem 2rem -1rem;
        background: $af-grigio;
    }

    .evt-text {
        margin-bottom: 1rem;
    }

    .evt-day {
        .program-title {
            font-size: 27px;
            font-weight: bold;
        }

        .evt-day-text {
            padding: 15px 30px 15px 15px;
            background: $af-grigio;
            flex-direction: column;

            .evt-day-title {
                text-align: left;
                font-size: 21px;
                margin-bottom: 10px;
                border-bottom: 1px solid black;
            }

            .evt-day-text-description {
            }

            .evt-day-text-footer {
                .evt-day-text-hour {
                    flex-basis: unset;
                    font-weight: normal;
                    border-bottom: unset;
                }
                .evt-day-text-register {
                    a.workshop-color:hover,
                    a.community-color:hover {
                        color: black !important;
                    }
                }
            }
        }
    }

    @media (min-width: 1220px) {
        .event-28 {
            padding: 0 4rem;

            .inner {
                width: 48%;
            }
        }
        .evt-day {
            .program-title {
                font-size: 20px;
            }

            .evt-day-text {
                .evt-day-title {
                    font-size: 18px;
                    border-bottom: unset;
                }

                .evt-day-text-description {
                    font-size: 16px;
                }

                .evt-day-moderator {
                    font-size: 14px;
                }

                .evt-day-text-footer {
                    .evt-day-text-hour {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    @media (min-width: 1600px) {
        .evt-day {
            .inline-title {
                .program-title {
                    font-size: 40px;
                }
                .btn-form-submit {
                    font-size: 25px;
                }
            }
        }
    }
}
