$bottom-logo-width: 210px;
$bottom-logo-width-500: 120px;
$bottom-logo-width-768: 270px;
$bottom-logo-width-992: 180px;
$bottom-logo-width-max: 210px;

#footer {
    padding: 4rem 5rem;
    font-size: 1.25em;
    .footer-partners {
        display: flex;
        column-gap: 96px;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 900px;
        margin: 0 auto;
    }

    .footer-partner {
        height: 150px;
        width: auto;

        img {
            height: 100%;
            width: auto;
        }

        .footer-partner-message {
            color: $text-color;
            text-align: center;
            margin-bottom: 0.5rem;
        }
        margin-bottom: 1.5rem;
    }
    .footer-host {
        width: $bottom-logo-width;
    }
}

@media screen and (min-width: 500px) {
}

@media screen and (min-width: 768px) {
    #footer {
        .footer-partner {
            .footer-partner-message {
                text-align: left;
                margin-bottom: 0.5rem;
            }
            margin-bottom: 1.5rem;
        }
        .footer-host {
            width: $bottom-logo-width-768;
        }
    }
}

@media screen and (min-width: 992px) {
    #footer {
    }
}

@media screen and (min-width: 1200px) {
    #footer {
    }
}
