@font-face {
    font-family: 'Fedra Sans Alt Std';
    src:
        url('../fonts/FedraSansAltStd-Bold.woff2') format('woff2'),
        url('../fonts/FedraSansAltStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Eugenio Sans';
    src: url('../fonts/EugenioSans-Black.eot');
    src:
        url('../fonts/EugenioSans-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSans-Black.woff2') format('woff2'),
        url('../fonts/EugenioSans-Black.woff') format('woff'),
        url('../fonts/EugenioSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Sans';
    src: url('../fonts/EugenioSans-Regular.eot');
    src:
        url('../fonts/EugenioSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSans-Regular.woff2') format('woff2'),
        url('../fonts/EugenioSans-Regular.woff') format('woff'),
        url('../fonts/EugenioSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Sans';
    src: url('../fonts/EugenioSans-Light.eot');
    src:
        url('../fonts/EugenioSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSans-Light.woff2') format('woff2'),
        url('../fonts/EugenioSans-Light.woff') format('woff'),
        url('../fonts/EugenioSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Sans';
    src: url('../fonts/EugenioSans-Bold.eot');
    src:
        url('../fonts/EugenioSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSans-Bold.woff2') format('woff2'),
        url('../fonts/EugenioSans-Bold.woff') format('woff'),
        url('../fonts/EugenioSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif';
    src: url('../fonts/EugenioSerif-MediumItalic.eot');
    src:
        url('../fonts/EugenioSerif-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerif-MediumItalic.woff2') format('woff2'),
        url('../fonts/EugenioSerif-MediumItalic.woff') format('woff'),
        url('../fonts/EugenioSerif-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif';
    src: url('../fonts/EugenioSerif-Black.eot');
    src:
        url('../fonts/EugenioSerif-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerif-Black.woff2') format('woff2'),
        url('../fonts/EugenioSerif-Black.woff') format('woff'),
        url('../fonts/EugenioSerif-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif';
    src: url('../fonts/EugenioSerif-Light.eot');
    src:
        url('../fonts/EugenioSerif-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerif-Light.woff2') format('woff2'),
        url('../fonts/EugenioSerif-Light.woff') format('woff'),
        url('../fonts/EugenioSerif-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif';
    src: url('../fonts/EugenioSerif-BlackItalic.eot');
    src:
        url('../fonts/EugenioSerif-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerif-BlackItalic.woff2') format('woff2'),
        url('../fonts/EugenioSerif-BlackItalic.woff') format('woff'),
        url('../fonts/EugenioSerif-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif';
    src: url('../fonts/EugenioSerif-Medium.eot');
    src:
        url('../fonts/EugenioSerif-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerif-Medium.woff2') format('woff2'),
        url('../fonts/EugenioSerif-Medium.woff') format('woff'),
        url('../fonts/EugenioSerif-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif';
    src: url('../fonts/EugenioSerif-Bold.eot');
    src:
        url('../fonts/EugenioSerif-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerif-Bold.woff2') format('woff2'),
        url('../fonts/EugenioSerif-Bold.woff') format('woff'),
        url('../fonts/EugenioSerif-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif';
    src: url('../fonts/EugenioSerif-Demi.eot');
    src:
        url('../fonts/EugenioSerif-Demi.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerif-Demi.woff2') format('woff2'),
        url('../fonts/EugenioSerif-Demi.woff') format('woff'),
        url('../fonts/EugenioSerif-Demi.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif Poster';
    src: url('../fonts/EugenioSerifPoster-Light.eot');
    src:
        url('../fonts/EugenioSerifPoster-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerifPoster-Light.woff2') format('woff2'),
        url('../fonts/EugenioSerifPoster-Light.woff') format('woff'),
        url('../fonts/EugenioSerifPoster-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif Poster';
    src: url('../fonts/EugenioSerifPoster-Regular.eot');
    src:
        url('../fonts/EugenioSerifPoster-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerifPoster-Regular.woff2') format('woff2'),
        url('../fonts/EugenioSerifPoster-Regular.woff') format('woff'),
        url('../fonts/EugenioSerifPoster-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif';
    src: url('../fonts/EugenioSerif-DemiItalic.eot');
    src:
        url('../fonts/EugenioSerif-DemiItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerif-DemiItalic.woff2') format('woff2'),
        url('../fonts/EugenioSerif-DemiItalic.woff') format('woff'),
        url('../fonts/EugenioSerif-DemiItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif Wide Caps';
    src: url('../fonts/EugenioSerifWideCaps-Bold.eot');
    src:
        url('../fonts/EugenioSerifWideCaps-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerifWideCaps-Bold.woff2') format('woff2'),
        url('../fonts/EugenioSerifWideCaps-Bold.woff') format('woff'),
        url('../fonts/EugenioSerifWideCaps-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif TF';
    src: url('../fonts/EugenioSerifTF-Regular.eot');
    src:
        url('../fonts/EugenioSerifTF-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerifTF-Regular.woff2') format('woff2'),
        url('../fonts/EugenioSerifTF-Regular.woff') format('woff'),
        url('../fonts/EugenioSerifTF-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif Poster';
    src: url('../fonts/EugenioSerifPoster-RegularItalic.eot');
    src:
        url('../fonts/EugenioSerifPoster-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerifPoster-RegularItalic.woff2') format('woff2'),
        url('../fonts/EugenioSerifPoster-RegularItalic.woff') format('woff'),
        url('../fonts/EugenioSerifPoster-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif';
    src: url('../fonts/EugenioSerif-LightItalic.eot');
    src:
        url('../fonts/EugenioSerif-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerif-LightItalic.woff2') format('woff2'),
        url('../fonts/EugenioSerif-LightItalic.woff') format('woff'),
        url('../fonts/EugenioSerif-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif';
    src: url('../fonts/EugenioSerif-BoldItalic.eot');
    src:
        url('../fonts/EugenioSerif-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerif-BoldItalic.woff2') format('woff2'),
        url('../fonts/EugenioSerif-BoldItalic.woff') format('woff'),
        url('../fonts/EugenioSerif-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif Poster';
    src: url('../fonts/EugenioSerifPoster-LightItalic.eot');
    src:
        url('../fonts/EugenioSerifPoster-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerifPoster-LightItalic.woff2') format('woff2'),
        url('../fonts/EugenioSerifPoster-LightItalic.woff') format('woff'),
        url('../fonts/EugenioSerifPoster-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif';
    src: url('../fonts/EugenioSerif-Regular.eot');
    src:
        url('../fonts/EugenioSerif-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerif-Regular.woff2') format('woff2'),
        url('../fonts/EugenioSerif-Regular.woff') format('woff'),
        url('../fonts/EugenioSerif-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Serif';
    src: url('../fonts/EugenioSerif-RegularItalic.eot');
    src:
        url('../fonts/EugenioSerif-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSerif-RegularItalic.woff2') format('woff2'),
        url('../fonts/EugenioSerif-RegularItalic.woff') format('woff'),
        url('../fonts/EugenioSerif-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
