body.access {
    #content-box {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }

    #access-form {
        width: 100%;
        max-width: 400px;
        margin-top: 2rem;
        font-size: 18px;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 992px) {
        #access-form {
            margin-left: 0;
            margin-right: auto;
        }
    }
}
